import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '~components/Container';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '30px',
    border: '1px #CCCCCC solid',
    borderTopWidth: 0,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    [theme.breakpoints.down('xs')]: {
      padding: '20px'
    }
  },
  header: {
    width: '100%',
    backgroundColor: '#FBFBFB',
    border: '1px #CCCCCC solid',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7
  },
  title: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '700',
    textAlign: 'left',
    margin: '16px auto',
    padding: '0px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px'
    }
  },
  content: {
    marginTop: 0,
    marginBottom: 0,
    '& > h3': {
      color: '#222222',
      fontSize: 18,
      lineHeight: '25px',
      fontFamily: 'Inter',
      fontWeight: '700',
      marginTop: 8,
      marginBottom: 16
    },
    '& > p': {
      color: '#222222',
      fontSize: 16,
      fontFamily: 'Inter',
      lineHeight: '26px',
      fontWeight: '400',
      marginTop: 8,
      marginBottom: 8
    },
    '& > p:empty': {
      height: '8px'
    },
    '& > p:first-child': {
      marginTop: 0
    }
  },
  point: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    padding: '8px 0px',
    [theme.breakpoints.down('xs')]: {
      gap: 16
    }
  },
  pointNumber: {
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '14px',
    borderRadius: '50%',
    color: '#FFFFFF',
    backgroundColor: '#000000',
    padding: '5px',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    marginTop: '8px'
  }
}));

const ArticleDiscussionCard = ({ discussion_title, points, containerSize }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={containerSize} className={classes.container}>
      <div className={classes.header}>
        <h5 className={classes.title}>{discussion_title}</h5>
      </div>
      <div className={classes.root}>
        {points &&
          points.map((point, index) => (
            <div
              key={String(point?.discussion_point?.text).substring(0, 20)}
              className={classes.point}
            >
              <div>
                <div className={classes.pointNumber}>{index + 1}</div>
              </div>
              <RichText externalClassName={classes.content} html={point?.discussion_point?.html} />
            </div>
          ))}
      </div>
    </Container>
  );
};

ArticleDiscussionCard.propTypes = {
  discussion_title: PropTypes.string,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      discussion_point: PropTypes.shape({
        text: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      })
    })
  ),
  containerSize: PropTypes.string
};

ArticleDiscussionCard.defaultProps = {
  discussion_title: '',
  points: [],
  containerSize: 'md'
};

export default ArticleDiscussionCard;
